async function fetchAndDisplayData() {
    const url = 'https://paws-walk.jp/wanlife/static/json/dogruninfo.json'; // JSONファイルのURLを指定
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('データの取得に失敗しました。');
        }
        const data = await response.json();
        console.log(data); // データ構造の確認

        // 営業状態とメモの更新
        document.getElementById('business-status').textContent = `現在ドッグランは${data['営業状態']}`;
        document.getElementById('business-notes').textContent = data['営業メモ'] || '';

        // 犬種と入場時間のリストを更新
        updateList('dog-types', data['利用状況']);
    } catch (error) {
        console.error('データの取得に失敗しました:', error);
    }
}

function updateList(elementId, items) {
    const element = document.getElementById(elementId);

    // ul要素の作成
    const ul = document.createElement('ul');
    ul.className = 'list-group';

    // タイトル行の追加
    const titleLi = document.createElement('li');
    titleLi.className = 'list-group-item';
    titleLi.innerHTML = '<div>犬種</div><div>入場時間</div>';
    ul.appendChild(titleLi);

    if (!Array.isArray(items) || items.length === 0) {
        const li = document.createElement('li');
        li.className = 'list-group-item';
        li.innerHTML = '';
        ul.appendChild(li);
    } else {
        // データ行の追加
        items.forEach(item => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const value = item[key];
                    const li = document.createElement('li');
                    li.className = 'list-group-item';
                    li.innerHTML = `<div>${key}</div><div>${value}</div>`;
                    ul.appendChild(li);
                }
            }
        });
    }
    // 既存の内容をクリアしてから新しいリストを追加
    element.innerHTML = '';
    element.appendChild(ul);
}

fetchAndDisplayData();
